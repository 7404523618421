<template>
  <!-- <nav class="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
    <router-link to="/about">Gear</router-link>
    <router-link to="/about">Booking</router-link>
    <router-link to="/about">Contact</router-link>
  </nav> -->
  <router-view/>
</template>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
</style>

<style>
#app {
  margin: 0;
  padding: 0;
  /* font-family: 'Archivo Black', sans-serif; */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* width: 100vw; */
}
body {
  margin: 0;
}
</style>

<style scoped>
.nav {
  display: none;
}
</style>
