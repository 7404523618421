<template>
    <div class="logo-container">
        <router-link to="/home">
            <img alt="LJR logo" class="logo" src="https://firebasestorage.googleapis.com/v0/b/littlejamaicarecordings-33518.appspot.com/o/LJR-Logo.png?alt=media&token=ae8a5aec-2f5b-4dc3-aa73-3700c11ff737">
        </router-link>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.logo-container {
    background-color: black;
    text-align: center;
}

.logo {
    height: 100vh;

    @media (max-width: 768px) {
        width: 100vw;
    }
}
</style>